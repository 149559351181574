/* Ensure the header is not restricting the sticky element */
.App-header {
  width: 100%;
  position: sticky;
  top: 0;
  z-index: 1100; /* Ensure it's above the .assembly-line content */
}

/* Add styles for the sticky element if not already present */
.DividerBar {
  position: -webkit-sticky; /* For Safari */
  position: sticky;
  top: 0; /* Stick to the top */
  z-index: 1000; /* Above other content */
  background-color: #fff; /* Background color */
  /* Other styles as needed for your design */
}

.assembly-line {
  display: flex;
  overflow-x: auto;
  white-space: nowrap;
  padding: 20px 0;
}

/* App.css */
.page {
  padding: 10px;
  margin-bottom: 3px;
  background-color: #fff; /* Ensure pages are white, override any inherited styles */
  border: 1px solid #ccc;
  border-radius: 4px;
  word-wrap: break-word; /* Ensures long words break and wrap to the next line */
  overflow-wrap: break-word; /* Alternative to word-wrap, for better support */
  white-space: normal; /* Ensures text wraps normally */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.238); /* Adds subtle shadow to the cards */
  transition: box-shadow 0.1s ease-in-out; /* Smooth transition for shadow effects */
}

.page:hover {
  box-shadow: 0 2px 10px rgb(21, 0, 255); /* Adds deeper shadow on hover for an interactive feel */
}

.selected {
  border: 2px solid #4CAF50; /* Example highlight color */
  background-color: #D2EFD2; /* Lighter shade for background */
}

.status {
  display: flex;
  flex-direction: column;
  border: 1px solid #ccc; /* Add border to contain the header and pages */
  margin: 10px; /* Add some margin around the status boxes */
  overflow: hidden; /* Prevents children from bleeding outside the border */
  background-color: #f8f8f8; /* Slightly off-white background for the status column */
  padding-left: 10px; /* Left padding */
  padding-right: 10px; /* Right padding */
}

/* Status Header and Actions */
.status-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  background-color: #f8f9fa;
  border-bottom: 1px solid #dee2e6;
}

.status-actions {
  display: flex;
  align-items: center;
  gap: 8px;
}

/* Button Styles */
.status-actions button {
  padding: 8px 12px;
  border-radius: 4px;
  border: 1px solid #ced4da;
  background-color: white;
  color: #495057;
  cursor: pointer;
  font-size: 14px;
  transition: all 0.2s ease;
}

.status-actions button:hover {
  background-color: #e9ecef;
  border-color: #adb5bd;
}

.status-actions button:active {
  background-color: #dee2e6;
}

/* Primary action buttons */
.status-actions button.primary {
  background-color: #007bff;
  color: white;
  border-color: #0056b3;
}

.status-actions button.primary:hover {
  background-color: #0056b3;
}

/* Status Update Controls */
.status-update-controls {
  display: flex;
  align-items: center;
  gap: 8px;
  background-color: white;
  padding: 4px;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  position: relative;
}

/* Select Dropdown */
.status-update-controls select {
  padding: 8px 12px;
  border-radius: 4px;
  border: 1px solid #ced4da;
  background-color: white;
  color: #495057;
  font-size: 14px;
  min-width: 200px;
  cursor: pointer;
  appearance: none;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 8px center;
  background-size: 16px;
}

.status-update-controls select:focus {
  outline: none;
  border-color: #80bdff;
  box-shadow: 0 0 0 0.2rem rgba(0,123,255,.25);
}

/* Update Button */
.status-update-controls .update-btn {
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #28a745;
  background-color: #28a745;
  color: white;
  cursor: pointer;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}

.status-update-controls .update-btn:hover {
  background-color: #218838;
  border-color: #1e7e34;
}

/* Navigation Arrows */
.nav-arrow {
  font-size: 24px;
  padding: 8px 12px;
  color: #6c757d;
}

.nav-arrow:hover {
  color: #495057;
}

/* Action Buttons Container */
.action-buttons {
  display: flex;
  align-items: center;
  gap: 8px;
}

.status-header h2 {
  margin: 0;
  flex-grow: 1;
}

.status-header button {
  margin-left: 10px; /* Spacing between buttons */
}

.status-box .status-header {
  /* Add styles for the header section */
  padding: 8px; /* Adjust padding as needed */
  border-bottom: 1px solid #ccc; /* Optional: if you want a separator */
}

.status-box .move-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 8px; /* Space between Select All and Move buttons */
  margin-bottom: 8px; /* Space between Move buttons and page list */
}

/* Progress Bar */
.progress-container {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background-color: #f8f9fa;
  padding: 8px;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  margin-top: 4px;
  z-index: 100;
}

.progress-bar {
  height: 4px;
  background-color: #e9ecef;
  border-radius: 2px;
  overflow: hidden;
}

.progress-bar-fill {
  height: 100%;
  background-color: #28a745;
  transition: width 0.3s ease;
}

.progress-text {
  font-size: 12px;
  color: #6c757d;
  text-align: center;
  margin-top: 4px;
}

/* App.css */

.create-phone-line-btn {
  margin: 0 10px;
  padding: 10px 20px;
  font-size: 1em;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s;
}

.create-phone-line-btn:hover {
  background-color: #367c39; /* Darker shade for hover effect */
}

/* For the Create Community button, similar styling as the Create Phone Line button */
.create-community-btn {
  margin: 0 10px;
  padding: 10px 20px;
  font-size: 1em;
  background-color: #4CAF50; /* Same green color */
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s;
}

.create-community-btn:hover {
  background-color: #367c39; /* Darker shade for hover effect */
}

.pages {
  margin-top: 6px; /* Ensures 6px gap between the plus icon and first page */
}

/* Ensure the first child after the header has a margin-top equal to the header's height */
.status > :first-child {
  margin-top: 1px; /* Adjust this value based on the actual height of your status-header */
}

.page-modal-backdrop {
  z-index: 1300; /* Keep the backdrop below the modal but above other content */
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.page-modal {
  z-index: 1400; /* Set this value higher than the backdrop's z-index */
  position: fixed; /* Changed to fixed to ensure it's relative to the viewport */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); /* Center the modal */
  padding: 20px;
  background: white;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 90%;
  max-height: 90vh;
  overflow-y: auto;
}

.page-modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  padding-bottom: 10px;
  border-bottom: 1px solid #eee;
}

.page-modal-actions {
  display: flex;
  gap: 10px;
}

.page-modal-actions button {
  padding: 8px 16px;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  background: #f0f0f0;
}

.page-modal-actions button:hover {
  background: #e0e0e0;
}

.page-attributes-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.page-attributes-table th,
.page-attributes-table td {
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid #eee;
}

.page-attributes-table th {
  background: #f5f5f5;
  font-weight: bold;
}

.page-attributes-table td:first-child {
  font-weight: 500;
  width: 200px;
}

.page-attributes-table a {
  color: #0066cc;
  text-decoration: none;
}

.page-attributes-table a:hover {
  text-decoration: underline;
}

.page-modal-footer {
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid #eee;
  text-align: right;
}

.dynamo-link {
  color: #666;
  text-decoration: none;
  font-size: 0.9em;
}

.dynamo-link:hover {
  text-decoration: underline;
}

.page {
  background-color: #f8f8f8;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 10px;
  margin-bottom: 3px; /* Maintain gap between pages */
}

.page-content {
  display: flex;
  flex-direction: column;
}

.page-title {
  font-weight: bold;
  margin-bottom: 10px; /* Space between title and attributes */
}

.page-attribute {
  margin-bottom: 5px; /* Space between attributes */
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.page-attribute strong {
  margin-right: 5px; /* Space between attribute name and value */
}

/* Add this to your CSS file */
.page-modal-attribute {
  margin-bottom: 8px;
}

.page-modal-attribute label {
  display: block;
  margin-bottom: 4px;
}

.page-modal-attribute input {
  width: 100%;
  padding: 8px;
  box-sizing: border-box; /* To ensure padding does not add to width */
}

/* App.css */
@font-face {
  font-family: 'SF Pro';
  src: url('/Users/thomasmaxon/Desktop/Claimyr local development/phone-line-assembly/public/fonts/SF-Pro-Display-Light.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'SF Pro';
  src: url('/Users/thomasmaxon/Desktop/Claimyr local development/phone-line-assembly/public/fonts/SF-Pro-Display-Bold.otf') format('opentype');
  font-weight: bold;
  font-style: normal;
}

body {
  font-family: 'SF Pro', sans-serif;
}

.claimyr-link {
  color: inherit; /* Inherit the text color from the parent */
  text-decoration: none; /* No underline by default */
}

.claimyr-link:hover {
  color: blue !important; /* Force text color to blue on hover */
  text-decoration: underline !important; /* Force underline on hover */
}